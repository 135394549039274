import { Col, Container, Row } from "react-bootstrap";

const Intro = () => {
  return (
    <Container id="formulario">
      <Row>
        <Container className="intro">
          <Row>
            <Col>
              <div className="text-center pb-5 pt-3">
                <h2 className="pt-2 mt-2 pb-md-5 pb-4 text-md-center text-start">ESTÁS CERCA DE TENER TU NUEVO OKM.</h2>
                <h3 className="text-md-center text-start">
                  COMPLETá TUS DATOS Y UN ASESOR TE BRINDARÁ toda información.{" "}
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Intro;
