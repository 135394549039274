import { Form, Col, Row, Button, Container, ProgressBar } from "react-bootstrap";
import { FaTemperatureHigh, FaWhatsapp, FaPhone } from "react-icons/fa";
import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import check from "../../assets/img/check.png";
import sorteamos from "../../assets/img/sorteamos.png";
import { useParams } from "react-router-dom";

import loadingImg from "../../assets/img/preload.gif";

const Formulario = ({ marca }) => {
  const [loading, setLoading] = useState(true);
  // console.log(vehicles);

  const query = new URLSearchParams(window.location.search);
  const utm_source = (query && query.get("utm_source")) || sessionStorage.getItem("utm_source");
  const utm_medium = (query && query.get("utm_medium")) || sessionStorage.getItem("utm_medium");

  console.log(window.location.pathname.split("/").pop());

  const [validated, setValidated] = useState(false);

  const [status, setStatus] = useState(null);
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    phone: "",
    origen: `${utm_source ? utm_source + " " : ""}${utm_medium ? utm_medium + " " : ""}`,
  });
  //console.log(toSend);

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    let template;

    if (marca === "fiat") {
      template = "marca_blanca_fiat";
    }

    if (marca === "jeep") {
      template = "marca_blanca_jeep";
    }

    if (marca === "ford") {
      template = "marca_blanca_ford";
    }

    if (marca === "peugeot") {
      template = "marca_blanca_peugeot";
    }

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      // console.log("no valido");
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      e.preventDefault();
      setStatus("cargando");
      console.log(toSend);

      emailjs
        .send("landingMontironi", template, toSend, "user_EIC1ELQk7I3NPaFMEDa64")
        .then((response) => {
          setStatus("ok");

          setTimeout(() => {
            setStatus(null);
            setValidated(false);
            setToSend({
              ...toSend,
              name: "",
              email: "",
              phone: "",
              origen: "",
            });
          }, 3000);
        })
        .catch((err) => {
          setStatus("error");
          setTimeout(() => {
            setStatus(null);
          }, 5000);
        });
    }
  };

  function updateProgressBar() {
    const totalFields = 3; // Total number of fields to fill

    let completedFields = 0;
    if (toSend.name !== "") completedFields++;
    if (toSend.phone !== "") completedFields++;
    if (toSend.email !== "") completedFields++;

    const progressPercentage = (completedFields / totalFields) * 100;

    return progressPercentage;
  }

  useEffect(() => {
    updateProgressBar();
  }, [toSend]);

  return (
    <Container fluid className="h-100 m-0 p-0 text-center">
      <div className="barra_superior">
        <ProgressBar now={updateProgressBar()} variant="success" />
      </div>

      <>
        {status === null && (
          <>
            <Container className="form-marca-blanca">
              <Form onSubmit={onSubmit} noValidate validated={validated}>
                <Row>
                  <Col md={12} xs={12} className="pb-3 text-start">
                    <label className="pb-3 text-start">Nombre y apellido *</label>
                    <Form.Control
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Nombre completo"
                      value={toSend.name}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                  <Col md={12} xs={12} className={`pb-3  text-start ${toSend.name !== "" ? "d-block" : "d-none"}`}>
                    <label className="pb-3">Celular *</label>
                    <Form.Control
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="0351 X XXXXXX"
                      value={toSend.phone}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                  <Col
                    md={12}
                    xs={12}
                    className={`pb-3  text-start ${toSend.name !== "" && toSend.phone !== "" ? "d-block" : "d-none"}`}
                  >
                    <label className="pb-3">Email *</label>
                    <Form.Control
                      id="email"
                      name="email"
                      type="email"
                      placeholder="email@email.com"
                      value={toSend.email}
                      onChange={handleChange}
                      required
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="pb-3 d-flex justify-content-center pt-4 pb-5 ">
                    <div>
                      <button
                        type="submit"
                        disabled={toSend.name === "" || toSend.phone === "" || toSend.email === "" ? true : false}
                        className={`btn-black ${
                          toSend.name === "" || toSend.phone === "" || toSend.email === "" ? "disabled" : ""
                        }`}
                      >
                        ENVIAR CONSULTA
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Container>
          </>
        )}

        {status === "cargando" && (
          <div className="msgHeader text-center pt-5 pb-5" id="enviando-mensaje">
            <img src={loadingImg} alt="Cargando..." className="pb-3" style={{ maxWidth: "150px" }} />
            <p>Estamos enviando tu consulta... </p>
          </div>
        )}

        {status === "ok" && (
          <div className="msgHeader text-center pt-5 pb-5" id="mensaje-enviado">
            <div>
              <img src={check} alt="Enviado" className="img-fluid" style={{ marginBottom: "20px", maxWidth: "60px" }} />
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "22px",
                  marginBottom: "0",
                  textAlign: "center",
                }}
              >
                Ya recibimos tu consulta.
              </p>
              <p style={{ textAlign: "center" }}>Un asesor se contactará con vos a la brevedad.</p>
            </div>
          </div>
        )}

        {status === "error" && (
          <div className="msgHeader pt-5 pb-5" id="mensaje-error">
            <div>
              <p
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "22px",
                  marginBottom: "0",
                  textAlign: "center",
                }}
              >
                Se ha producido un error
              </p>
              <p style={{ textAlign: "center" }}>Vuelva a intentarlo mas tarde</p>
            </div>
          </div>
        )}
      </>
    </Container>
  );
};

export default Formulario;
