import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/scss/styles.scss";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Error404 from "./components/Error404";
import Intro from "./components/Intro/Intro";

import Slider from "./components/Slider";
import WspFloat from "./components/WspFloat/WspFloat";
import Formulario from "./components/Formulario/Formulario";
import { Col, Container, Row } from "react-bootstrap";
import Marcas from "./components/Marcas/Marcas";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Container fluid>
            <Row>
              <Container className="intro">
                <Row>
                  <Col>
                    <h5 className="text-center pb-5 pt-5">Seleccioná la opción que necesites</h5>
                    <Marcas />
                  </Col>
                </Row>
              </Container>
            </Row>
          </Container>
        </Route>
        <Route exact path="/fiat">
          <Slider marca="fiat" />
          <Intro />
          <Formulario marca="fiat" />
        </Route>

        <Route exact path="/ford">
          <Slider marca="ford" />
          <Intro />
          <Formulario marca="ford" />
        </Route>

        <Route exact path="/peugeot">
          <Slider marca="peugeot" />
          <Intro />
          <Formulario marca="peugeot" />
        </Route>

        <Route exact path="/jeep">
          <Slider marca="jeep" />
          <Intro />
          <Formulario marca="jeep" />
        </Route>

        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
