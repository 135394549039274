import { useState, useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { getFirestore } from "../../config/firebase";
import Loading from "../Loading/Loading";
import divisor from "../../assets/img/oport-divisor.png";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";

import fiat_desktop from "../../assets/img/slider/fiat_desktop.jpg";
import jeep_desktop from "../../assets/img/slider/jeep_desktop.jpg";
import peugeot_desktop from "../../assets/img/slider/peugeot_desktop.jpg";
import ford_desktop from "../../assets/img/slider/ford_desktop.jpg";

const Slider = ({ tipo, marca }) => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  let location = useLocation();

  // console.log(location.pathname.split("/").pop(), "el tipo");

  useEffect(() => {
    const obtenerDatos = async () => {
      const db = getFirestore();
      try {
        const data = await db.collection("sliders-mesa-nomada").get();
        const arrayData = data.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setLoading(false);
        //console.log(arrayData);
        setBanners(arrayData);
      } catch (error) {
        console.log(error);
      }
    };
    obtenerDatos();
  }, []);

  console.log(banners);

  const renderSlider = () => {
    if (marca === "fiat") {
      return fiat_desktop;
    }
    if (marca === "jeep") {
      return jeep_desktop;
    }
    if (marca === "peugeot") {
      return peugeot_desktop;
    }
    if (marca === "ford") {
      return ford_desktop;
    }
  };

  return loading ? (
    <Loading styles="mt-5 pt-5 pb-5" />
  ) : (
    <>
      <Container fluid className="p-0 m-0" onClick={() => scroll.scrollToBottom()} style={{ cursor: "pointer" }}>
        <Row className="p-0 m-0">
          <Col md={12} className="mx-auto p-0 m-0">
            <div className="">
              <Carousel controls={false} indicators={false}>
                <Carousel.Item key={1}>
                  <a href={"#formulario"} target={"_self"} rel="noreferrer">
                    <img className="d-block img-fluid mx-auto slider-marca" src={renderSlider()} alt="Montironi" />
                  </a>
                </Carousel.Item>
              </Carousel>
            </div>
            {/* <div className="d-md-none d-block">
              <Carousel>
                <Carousel.Item key={1}>
                  <img className="d-block w-100 img-fluid" src={mobile} alt="Montironi" />
                </Carousel.Item>
              </Carousel>
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Slider;
