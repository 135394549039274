import { Link } from "react-router-dom";

const BoxMarca = ({ marca, link }) => {
  return (
    <Link to={link}>
      <p className={`box ${marca}`} rel="noreferrer"></p>
    </Link>
  );
};

export default BoxMarca;
